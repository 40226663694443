<template lang="pug">
.container
  h1.title.mt Salida digital
  .card
    .card-content
      app-form(@submit='submit' :is-saving='isSaving')
        field(label='Nombre' field='name')
          input#name.input(type='text' placeholder='Ejm: Salida uno' maxlength='30' required v-model='variable.name')
        field(label='Estado 0' field='status0')
          input#open.input(type='text' placeholder='Ejm: Abrir' maxlength='50' required v-model='variable.status0')
        field(label='Estado 1' field='status1')
          input#close.input(type='text' placeholder='Ejm: Cerrar' maxlength='50' required v-model='variable.status1')
        field
          b-switch(v-model='variable.active') Activo
  b-loading(:is-full-page='false' v-model='isLoading')
</template>

<script>
import variablesService from '@/services/variables.service'
import toastService from '@/services/toast.service'

export default {
  data () {
    return {
      isLoading: false,
      isSaving: false,
      variable: {}
    }
  },
  async created () {
    this.isLoading = true
    const result = await variablesService.getOne(this.$route.params.id)
    if (result) {
      this.variable = result
    }
    this.isLoading = false
  },
  methods: {
    async submit () {
      this.isSaving = true
      const result = await variablesService.save(this.variable)
      if (result) {
        toastService.show('Datos guardados')
        const onSave = this.$route.query.onSave
        if (onSave) {
          this.$router.push({ name: onSave })
        }
      }
      this.isSaving = false
    }
  }
}
</script>
